$colors: (
  gray100: #0e1117,
  gray200: #161a23,
  gray400: #1e232f,
  gray500: #343944,
  gray700: #8e9197,
  gray900: #e5e5e5,
  blue500: #4db0c2,
  primary: #4db0c2,
  secondary: #d46148,
  error: #d44848,
  success: #00ffb2,
  neutral: #343944,
);

@function color($name) {
  @return map-get($colors, $name);
}
