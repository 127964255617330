.playlists__actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: pxtorem(10);
}

.playlist__row {
  align-items: center;
  display: flex;
  margin-bottom: pxtorem(12);
  margin-top: pxtorem(12);
  user-select: none;
  width: 100%;
}

.playlist__row-header {
  margin-bottom: pxtorem(4);
  margin-top: pxtorem(16);
  padding-left: 33px;
}

.playlist__col {
  padding-left: pxtorem(10);
  padding-right: pxtorem(10);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.playlist__col--name .MuiCheckbox-root {
  margin-bottom: 0;
  margin-top: 0;
}

.playlist__col--name {
  flex-grow: 1;
}

.playlist__col--views {
  flex-shrink: 0;
  text-align: right;
  width: 54px;
}

.playlist__col--date {
  flex-shrink: 0;
  width: 138px;
}

.playlist__col--action {
  flex-shrink: 0;
  width: 110px;
}

.playlists__items {
  padding-bottom: pxtorem(42);
  padding-top: pxtorem(24);
}

.playlists__items--grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}

.playlists__items--list {
  display: flex;
  flex-wrap: wrap;
}

.playlists__video-card {
  margin-top: pxtorem(24);
}
