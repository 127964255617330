@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function pxtorem($num) {
  @return #{strip-unit($num) / 16}rem;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function spacing($space) {
  @return pxtorem(map-deep-get($spacing, $space));
}
