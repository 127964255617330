.video-card {
  display: inline-block;
  width: pxtorem(168);
}

.video-card__img-container {
  height: pxtorem(94);
  margin-bottom: pxtorem(10);
  position: relative;
  width: 100%;
}

.video-card__progress {
  background-color: color(primary);
  bottom: 0;
  height: 4px;
  position: absolute;
}

.video-card__img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.video-card__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-card .video-card__button {
  padding: 0;
}
