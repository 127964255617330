$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1400px,
);

$spacing: (
  xs: 10px,
  sm: 20px,
  md: 30px,
  lg: 40px,
  xl: 50px,
  xxl: 60px,
);

$container-max-widths: (
  sm: 420px,
  md: 720px,
  lg: 960px,
);

$headerHeight: 72px;
