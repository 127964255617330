.user-form--indent {
  padding: {
    bottom: pxtorem(32);
    left: pxtorem(32);
    right: pxtorem(32);
  }
}

.user-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
  text-align: center;
}

.user-form .user-form__text {
  margin-top: pxtorem(28);
  max-width: pxtorem(400);
}

.user-form .user-form__form {
  display: flex;
  flex-direction: column;
  margin-top: pxtorem(44);
  max-width: pxtorem(830);
  text-align: left;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.user-form .user-form__form-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:first-child) {
    margin-top: pxtorem(24);
  }

  @media (min-width: 768px) {
    &:not(:first-child) {
      margin-top: 0;
    }
  }
}

.user-form .user-form__form-item {
  margin-left: 3vw;
  margin-right: 3vw;
  max-width: 80vw;
  width: calc(100% - 6vw);

  &:not(:first-of-type) {
    margin-top: pxtorem(24);
  }
}

.user-form .user-form__form-controls {
  margin-top: pxtorem(48);
}
