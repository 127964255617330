.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.header-layout {
  flex-shrink: 0;
}

.main {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  width: 100%;

  &--no-scroll {
    overflow: hidden;
  }
}
