.connect__type-text {
  text-transform: capitalize;
}

.connect-card-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.connect-card {
  border: 1px solid #343944;
}

.connect-actions {
  white-space: nowrap;

  .connect-action-button {
    min-width: 0;
    padding: 0;
  }
}

.connect-card.user-form {
  margin-top: 0;
}
