.column-layout {
  max-height: 100%;
  min-height: 100%;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.column-layout__col {
  background: color(gray200);
  border-left: 1px solid color(gray500);
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;

  > * {
    padding: 0 spacing(sm);
  }

  &:first-of-type {
    border-left: unset;
  }

  &:nth-child(even) {
    background: color(gray100);
  }

  @include media-breakpoint-up(xl) {
    > * {
      padding: 0;
    }
  }
}

.column-layout__col-content {
  height: 100%;
  margin: 0 auto;
  width: 100%;

  .column-layout--scrollable & {
    height: auto;
  }

  @include media-breakpoint-up(lg) {
    max-width: 600px;
  }
}

.column-layout__content-main {
  height: 100%;
  padding-top: spacing(md);
}

.column-layout__header {
  border-top: 1px solid color(gray500);
  flex-shrink: 0;
  min-height: 64px;

  > * {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.column-layout__header--text {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.column-layout__content {
  border-top: 1px solid color(gray500);
  flex-grow: 1;
  min-height: 50vh;
  overflow: auto;

  .column-layout--scrollable & {
    overflow: auto;

    .column-layout__content-main {
      height: inherit;
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: 0;
    overflow: hidden;
  }
}
