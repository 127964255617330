.header {
  align-items: center;
  background: #161a23;
  display: flex;
  flex-direction: row;
  height: $headerHeight;
  justify-content: space-between;
}

.header__logo {
  margin: 0 24px;
}

.header__profile-menu {
  margin: 0 24px;
}
